import { PRISMIC_SLICES } from '~/lib/prismic-types'

import Announcement from '~/components/Slices/Announcement'
import CategoryGridEdito from '~/components/Slices/CategoryGridEdito'
import ColumnEdito from '~/components/Slices/ColumnEdito'
import CoverImage from '~/components/Slices/CoverImage'
import DiptychOrTriptych from '~/components/Slices/DiptychOrTriptych'
import EditoColumn from '~/components/Slices/EditoColumn'
import EditoDypticImage from '~/components/Slices/EditoDypticImage'
import EditoImage from '~/components/Slices/EditoImage'
import EditoImageTitleText from '~/components/Slices/EditoImageTitleText'
import EditoPush from '~/components/Slices/EditoPush'
import EditoTitleImageText from '~/components/Slices/EditoTitleImageText'
import FormEventSlice from '~/components/Slices/FormEventSlice'
import GiftCardBalanceChecker from '~/components/Slices/GiftCardBalanceChecker'
import GridLookbookImages from '~/components/Slices/GridLookbookImages'
import GridLookbookLinks from '~/components/Slices/GridLookbookLinks'
import GridLookbookSingleImage from '~/components/Slices/GridLookbookSingleImage'
import GridLookbookSliderMultipleLayout from '~/components/Slices/GridLookbookSliderMultipleLayout'
import HeaderStory from '~/components/Slices/HeaderStory'
import HeroMain from '~/components/Slices/HeroMain'
import HeroMainTeasing from '~/components/Slices/HeroMainTeasing'
import HeroOneLinerText from '~/components/Slices/HeroOneLinerText'
import ImmersiveEditoDypticImages from '~/components/Slices/ImmersiveEditoDypticImages'
import ImmersiveEditoHero from '~/components/Slices/ImmersiveEditoHero'
import ImmersiveEditoHeroTeasing from '~/components/Slices/ImmersiveEditoHeroTeasing'
import ImmersiveEditoImagesSlider from '~/components/Slices/ImmersiveEditoImagesSlider'
import ImmersiveEditoTitleTextCta from '~/components/Slices/ImmersiveEditoTitleTextCta'
import MasonryImage from '~/components/Slices/MasonryImage'
import MasonryImageEdito from '~/components/Slices/MasonryImageEdito'
import ParcelLabReturnWidget from '~/components/Slices/ParcelLabReturnWidget'
import ParcelLabTrackingWidget from '~/components/Slices/ParcelLabTrackingWidget'
import ProductsGrid from '~/components/Slices/ProductsGrid'
import ProductsSlider from '~/components/Slices/ProductsSlider'
import PushCover from '~/components/Slices/PushCover'
import PushImage from '~/components/Slices/PushImage'
import PushImageCover from '~/components/Slices/PushImageCover'
import PushProduct from '~/components/Slices/PushProduct'
import PushProductsImages from '~/components/Slices/PushProductsImages'
import PushProductsSlider from '~/components/Slices/PushProductsSlider'
import ShopifyQuizz from '~/components/Slices/ShopifyQuizz'
import StoriesGridImage from '~/components/Slices/StoriesGridImage'
import StoriesSlider from '~/components/Slices/StoriesSlider'
import VideoPlayer from '~/components/Slices/Video'
import Wysiwyg from '~/components/Slices/Wysiwyg'

export default {
  [PRISMIC_SLICES.ANNOUNCEMENT]: Announcement,
  [PRISMIC_SLICES.CATEGORY_GRID_EDITO]: CategoryGridEdito,
  [PRISMIC_SLICES.COLUMN_EDITO]: ColumnEdito,
  [PRISMIC_SLICES.COVER_IMAGE]: CoverImage,
  [PRISMIC_SLICES.EDITO_COLUMN]: EditoColumn,
  [PRISMIC_SLICES.EDITO_DYPTIC_IMAGE]: EditoDypticImage,
  [PRISMIC_SLICES.EDITO_IMAGE]: EditoImage,
  [PRISMIC_SLICES.EDITO_IMAGE_TITLE_TEXT]: EditoImageTitleText,
  [PRISMIC_SLICES.EDITO_TITLE_IMAGE_TEXT]: EditoTitleImageText,
  [PRISMIC_SLICES.MASONRY_IMAGE_EDITO]: MasonryImageEdito,
  [PRISMIC_SLICES.PUSH_IMAGE]: PushImage,
  [PRISMIC_SLICES.PUSH_IMAGE_COVER]: PushImageCover,
  [PRISMIC_SLICES.VIDEO_PLAYER]: VideoPlayer,
  [PRISMIC_SLICES.WYSIWYG]: Wysiwyg,
  [PRISMIC_SLICES.STORIES_SLIDER]: StoriesSlider,
  [PRISMIC_SLICES.MASONRY_IMAGE]: MasonryImage,
  [PRISMIC_SLICES.PUSH_COVER]: PushCover,
  [PRISMIC_SLICES.GRID_LOOKBOOK_IMAGES]: GridLookbookImages,
  [PRISMIC_SLICES.GRID_LOOKBOOK_SINGLE_IMAGE]: GridLookbookSingleImage,
  [PRISMIC_SLICES.GRID_LOOKBOOK_SLIDER_MULTIPLE_LAYOUT]:
    GridLookbookSliderMultipleLayout,
  [PRISMIC_SLICES.HEADER_STORY]: HeaderStory,
  [PRISMIC_SLICES.PUSH_PRODUCT_V2]: PushProduct,
  [PRISMIC_SLICES.GRID_LOOKBOOK_LINKS]: GridLookbookLinks,
  [PRISMIC_SLICES.HERO_MAIN]: HeroMain,
  [PRISMIC_SLICES.HERO_MAIN_TEASING]: HeroMainTeasing,
  [PRISMIC_SLICES.IMMERSIVE_EDITO_HERO]: ImmersiveEditoHero,
  [PRISMIC_SLICES.IMMERSIVE_EDITO_DYPTIC_IMAGES]: ImmersiveEditoDypticImages,
  [PRISMIC_SLICES.IMMERSIVE_EDITO_IMAGES_SLIDER]: ImmersiveEditoImagesSlider,
  [PRISMIC_SLICES.IMMERSIVE_EDITO_TITLE_TEXT_CTA]: ImmersiveEditoTitleTextCta,
  [PRISMIC_SLICES.IMMERSIVE_EDITO_HERO_TEASING]: ImmersiveEditoHeroTeasing,
  [PRISMIC_SLICES.HERO_ONE_LINER_TEXT]: HeroOneLinerText,
  [PRISMIC_SLICES.PARCEL_LAB_TRACKING_WIDGET]: ParcelLabTrackingWidget,
  [PRISMIC_SLICES.PARCEL_LAB_RETURN_WIDGET]: ParcelLabReturnWidget,
  [PRISMIC_SLICES.PRODUCTS_SLIDER_V2]: ProductsSlider,
  [PRISMIC_SLICES.PRODUCTS_GRID_V2]: ProductsGrid,
  [PRISMIC_SLICES.PUSH_PRODUCTS_IMAGES_V2]: PushProductsImages,
  [PRISMIC_SLICES.PUSH_PRODUCTS_SLIDER_V2]: PushProductsSlider,
  [PRISMIC_SLICES.SHOPIFY_QUIZZ]: ShopifyQuizz,
  [PRISMIC_SLICES.DIPTYCH_OR_TRIPTYCH]: DiptychOrTriptych,
  [PRISMIC_SLICES.EDITO_PUSH]: EditoPush,
  [PRISMIC_SLICES.GIFT_CARD_BALANCE_CHECKER]: GiftCardBalanceChecker,
  [PRISMIC_SLICES.STORIES_GRID_IMAGE]: StoriesGridImage,
  [PRISMIC_SLICES.FORM_EVENT_SLICE]: FormEventSlice,
}

// 46 imports
